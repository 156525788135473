.btn-close {
  margin-left: 340px;
}

.P-Update-Success-1 {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.P-Update-Success-2-change-password {
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 20;
  padding: 30px 70px;
  width: 100%;
  max-width: 550px;
}

#float-label {
  margin-bottom: 15px;
}

.P-Update-Success-3 {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  z-index: 10;
}

.P-Update-Success-4 {
  background-color: white;
  width: 300px;
  z-index: 20;

  padding-left: 20px;
  padding-right: 20px;
}

.error-msg-2 {
  color: #be1e10;
  font-size: 10px;
  font-weight: 600;
  margin: 2px 0 5px 0;
  width: 100%;
}

.close-button {
  margin-left: 250px;
}

.edit-profile-fields {
  display: flex;
  width: calc(100% + 10px); //to override margin
  box-sizing: border-box;
}

//if screen is < 500px
@media only screen and (max-width: 500px) {
  /* For mobile phones: */
  .P-Update-Success-2-change-password {
    padding: 10px 30px;
  }
}
