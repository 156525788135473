.status-dot {
  border-radius: 50%;
  display: inline-block;
}

.large {
  height: 11px;
  width: 11px;
  margin-right: 4px;
}

.small {
  height: 9px;
  width: 9px;
  margin-right: 7px;
}
