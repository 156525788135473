.dmu-container {
  flex: 1;
  width: 100%;
  padding: 1.5rem;
  padding-top: 0px;
  background-color: #fafafa;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}

.truck-card-container {
  width: 365px;
  height: 154px;
  border-radius: 10px;
  box-shadow: 0px 1px 6px #0000001a;
  background-color: white;
  position: relative;
  cursor: pointer;
}
.selected-truck {
  border: 1.5px solid #3183f5; /* You can customize the border style and color */
}

.truck-image-container {
  width: 216px;
  height: 157px;
  border-radius: 10px;
  position: absolute;
  right: -2rem;
  top: -1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  opacity: 1;
}

.active-truck-color {
  background-color: #acefc1;
}

.inactive-truck-color {
  background-color: #e3e3e3;
}

.truck-image-size {
  aspect-ratio: attr(width) / attr(height);
  background-color: #FAFAFA;
}

//subheader text
.subheader {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

// Timeline graphs
.timeline-container{
  background: #FFFFFF;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #E3E3E3;
  border-top: 0px;
  padding: 10px 20px;
  padding-bottom: 5px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border-top-left-radius: 0px;
}

.tab {
  border: 1px solid #e3e3e3;
  background: #FAFAFA;
  padding: 5px 15px;
  font-size: 14px;
  cursor: pointer;
}
.highlighted-tab {
  background-color: white;
  border-bottom: 0px;
  color: #000000;
  font-weight: 600;
  font-size: 14px;
}

.scrollable-chart {
  overflow-x: auto;
}

.scrollable-chart::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #0581FC;
  width: 2px;

}

.most-least-image {
  background-color: #FAFAFA;
  border: 1px solid #EBEBEB;
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.timeline-label {
  transform: rotate(-90deg); // Rotate the text by -90 degrees 
  white-space: nowrap; 
  width: 1em; 
}

.granularity-toggle {
  border: 1px solid #e1e8f4;
  color: #6f7070;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  border-radius: 10px;
  align-items: center;
  cursor: pointer;

}
.selected-toggle {
  background-color: black;
  color: white;
  border-radius: 16px;
  padding: 4px 15px;
}
.unselected-toggle {
  padding: 4px 10px;
}

.gray-text{
  color: #6F7070
}
.target-line{
  width: 10px;
  height: 0px;
  border: 1px solid #3AA75F;
}

.highlighted-date {
  background-color: #3183f5;
  border-radius: 11px;
  color: white;
  padding: 1px 9px;
}

// colors
.push {
  background-color: #7638D9;
}
.speed {
  background-color: #ED5ADC;
}
