.summaryHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    gap: 10px;
}

.datePicker {
    position: relative;
    display: flex;
    gap: 10px;
}

.assets {
    display: flex;
    border: 1px solid #EBEBEB;
    border-radius: 4px;
    padding: 5px;
    align-items: center;
    gap: 12px;
    font-weight: 600;
    font-size: 12px;
}

.assetCheck {
    border: 1px solid #BEC5D1;
    border-radius: 4px;
    width: 85px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 8px;
}

.checked {
    background-color: #D9E4FF;
    border: none;
}

.checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid #ccc;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    position: relative;
}

.checkbox:checked {
    background-color: #1E54CC;
    border-color: #1E54CC;
}

.checkbox:checked::after {
    content: '';
    display: block;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
}


.dateContainer {
    position: absolute;
    z-index: 1;
    top: 33px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #EBEBEB;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.monthPicker {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow: auto;
}


.monthOptions {
    padding: 8px 20px;
    cursor: pointer;
}


.monthOptions:hover {
    background-color: #EBEBEB;
}


.fromTo {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 7px 20px;
    margin-top: 13px;
}


.from {
    border: 1px solid #EBEBEB;
    display: flex;
    align-items: center;
    gap: 11px;
    flex-grow: 1;
    color: #707070;
    font-size: 12px;
    padding: 3px 5px;
    color: #000000;
    font-size: 12px;
    font-weight: 600;
}


.dateVal {
    border: 1px solid #EBEBEB;
    border-radius: 8px;
    background-color: #FAFAFA;
    padding: 3px 20px;
}


.btnContainer {
    display: flex;
    padding: 20px;
    width: 100%;
    gap: 20px;
    justify-content: space-between;
}


.btn {
    background-color: white;
    border: 1px solid #2B2D2F;
    border-radius: 4px;
    width: 80px;
    height: 30px;
    color: #2B2D2F;
    font-size: 12px;
    font-weight: bold;
}

.btnSm {
    height: 25px;
}

.fill {
    color: #FFFFFF;
    background: #2B2D2F
}

.disabledBtn {
    color: #9FA2A7;
    background: #EAEAEA;
    border: none;
    cursor: not-allowed !important;
}

.dateButton {
    display: flex;
    border: 1px solid #EBEBEB;
    border-radius: 3px;
    font-size: 12px;
}

.modalToggle {
    cursor: pointer;
    padding: 4px 24px 4px 9px;
    background: #2B2D2F;
    border-radius: 3px 0px 0px 3px;
    opacity: 0.5;
    color: white;
    display: flex;
    gap: 15px;
    align-items: center;

    img {
        width: auto;
        height: 21px;
    }
}

.modeToggle {
    display: flex;
    padding: 4px;
}

.toggle {
    padding: 2px 4px;
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
        height: 10px;
        width: auto;
    }
}

.dateDisplay {
    border: 1px solid #EBEBEB;
    border-radius: 8px;
    background-color: #FAFAFA;
    text-align: center;
    cursor: pointer;
    padding: 3px 11px;
    min-width: 150px;
}

.headerRight {
    display: flex;
    gap: 20px;
    align-items: flex-end;
}

.dateInfo {
    font-size: 10px;
    color: #707071;
}

.download {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    cursor: pointer;
    width: 85px;
    height: 25px;
    border: 1px solid #BEC5D1;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;

    img {
        height: 10px;
        width: auto;
    }
}

.summaryContainer {
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #EBEBEB;
    border-radius: 4px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    flex-grow: 1;
}


.summaryTitle {
    font-size: 14px;
    font-weight: 600;
}


.summaryCardsContainer {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
}


.verticalLine {
    border: 1px solid #E3E3E3;
    margin: 25px 0px 0px 0px;
}


.summary-section-title {
    font-size: 14px;
    font-weight: 600;
}


.sectionContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}


.sections-container {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    padding: 0px 20px;
    /* border: 2px solid black; */
}

.pushes {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    color: #5B5B5B;
    font-size: 12px;
    font-weight: normal;
}


.summary-message-key {
    color: #5B5B5B;
    font-size: 10px;
    text-wrap: nowrap;
}

.non-chart-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-right: 20px;
}

.metric {
    display: flex;
    gap: 12px;
}


.colorCode {
    margin-top: 10px;
    border-radius: 50%;
    height: 9px;
    width: 9px;
}

.valueBox {
    display: flex;
    flex-direction: column;
    gap: 4px;
}


.value {
    font-size: 18px;
    font-weight: 600;
    text-wrap: nowrap;
}

.textSm {
    font-size: 10px;
}

.subText {
    font-size: 10px;
    text-wrap: nowrap;
}

.subText2 {
    color: #5B5B5B;
    font-size: 9px;
    text-wrap: nowrap;
}


.val1 {
    color: #5B5B5B;
    font-size: 14px;
    text-wrap: nowrap;
}


.val2 {
    font-size: 12px;
    font-weight: 600;
    text-wrap: nowrap;
}


.val3 {
    font-size: 12px;
    font-weight: bold;
    color: #5B5B5B;
    text-wrap: nowrap;
}

.img {
    margin-top: 10px;
    height: 14px;
    width: auto;
}


.monthlySummary {
    display: flex;
    gap: 27px;
}


.monthlyPushes {
    display: flex;
    align-items: center;
    padding-top: 60px;
    flex-direction: column;


    img {
        width: auto;
        height: 16px;
    }
}


.monthlyMetric {
    min-width: 158px;
    background: rgba(118, 56, 217, 0.28);
    padding: 6px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    border-radius: 4px;
    color: black;
    font-size: 25px;
    font-weight: 600;
}


.monthlySubtext {
    font-size: 10px;
    padding-top: 7px;
}


.monthlyPushesLow {
    color: #5B5B5B;
    font-size: 10px;
    padding-top: 13px;
}

.loaders {
    border: 2px solid #EBEBEB;
    background-color: rgba(235, 235, 235, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    width: 30%;
}

.loaderMetricVal {
    font-weight: bold;
    font-size: 32px;
}

.loaderMetricSub {
    color: #5B5B5B;
}

.liveData {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #E3E3E3;
    border-right: 1px solid #E3E3E3;
    border-bottom: 1px solid #E3E3E3;
    width: 70%;
}

.liveTop {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E3E3E3;
    padding: 20px;
    padding-bottom: 10px;
}

.liveTopLeft {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 10px;
}

.liveTitleSub {
    color: #5B5B5B;
    font-style: italic;
}

.liveTopRight {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 35px;
}

.liveRightSub {
    color: #5B5B5B;
    font-size: 14px;
    display: flex;
    gap: 8px;
    align-items: center;

    img {
        height: 30px;
        width: auto;
    }
}

.liveStatusCont {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
}

.liveStatus {
    display: flex;
    align-items: center;
    gap: 4px;
}

.liveStatusDot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
}

.liveBottom {
    /* border: 1px solid black; */
    height: 100%;
    overflow-x: auto;
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 10px;
}

.liveAsset {
    /* border: 1px solid red; */
    width: 185px;
    height: 146px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
}

.divider {
    height: 100px;
    border: 1px solid #E3E3E3;
}

.liveAssetTop {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: bold;
    font-size: 18px;
}

.liveAssetStatus {
    height: 15px;
    width: 15px;
    border-radius: 50%;
}

.liveAssetBottom {
    display: flex;
    gap: 20px;
    font-size: 14px;
}

.liveAssetLabel {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.liveAssetValue {
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

@media screen and (max-height: 900px) {
    .summaryTitle {
        font-size: 12px;
    }

    .summary-section-title {
        font-size: 12px;
    }

    .sections-container {
        gap: 5px;
        padding: 0px;
    }

    .dateButton {
        font-size: 10px;
    }

    .modalToggle {
        gap: 10px;

        img {
            height: 18px;
        }
    }

    .download {
        font-size: 10px;

        img {
            height: 10px;
        }
    }

    .monthlyPushes {
        padding-top: 40px;

        img {
            height: 14px;
        }
    }

    .monthlyMetric {
        min-width: 130px;
        padding: 6px 0px;
        gap: 4px;
        font-size: 18px;
    }

    .monthlySubtext {
        padding-top: 7px;
    }

    .monthlyPushesLow {
        padding-top: 7px;
    }

    .valueBox {
        gap: 2px;
    }

    .value {
        font-size: 16px;
    }

    .val1 {
        font-size: 12px;
    }

    .val2 {
        font-size: 10px;
    }

    .val3 {
        font-size: 10px;
    }
}

@media screen and (max-width: 1368px) {
    .headerRight {
        gap: 10px;
    }

    .download {
        gap: 3px;
        min-width: 85px;
        height: 25px;
    }

    .dateDisplay {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 11px;
        min-width: 120px;
    }

    .modalToggle {
        padding: 4px 24px 4px 9px;
        gap: 10px;
    }

    .toggle {
        padding: 2px 4px;
        width: 20px;

        img {
            height: 10px;
            width: auto;
        }
    }
}

@media screen and (max-width: 1170px) {
    .summaryHeader {
        flex-direction: column;
        align-items: flex-start;
    }
}