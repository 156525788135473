.profile-div {
  z-index: 5;
  position: absolute;
  right: 36px;
  top: 50px;
}

.rounded-corners {
  background-color: #ebebeb;
  overflow: hidden;
  box-shadow: 0px 3px 6px #00000029;
}

.text1 {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 3px;
}

#profileCard {
  cursor: pointer;
  height: 4.2rem;
  width: 10rem;
  overflow: hidden;
  box-shadow: 0px 3px 6px #00000029;
  border: none;
  font-size: 12px;
}
.hover-style:hover {
  background-color: #cce4dc;
}
.hover-style {
  background-color: white;
  text-align: left;
}

.btn-group button {
  color: rgb(5, 6, 6);

  cursor: pointer;
  width: fit-content;
  display: block;
  overflow: hidden;
}
#change-btn {
  width: 100%;
  height: 60px;
  border-left: none;
  border-right: none;
  border-top: #0f614e solid 1px;
  border-bottom: #0f614e solid 1px;
  text-align: start;
  padding-left: 12px;
  font-size: 14px;
}

#cancel-btn {
  border: none;
  width: 100%;
  height: 60px;
  text-align: start;
  padding-left: 12px;
  font-size: 14px;
}

.btn-group button:hover {
  background-color: #489c88;
  color: #fff;
}

#imgModal {
  margin-left: 165px;
  margin-top: 10px;
}

.large-avatar {
  width: 60px;
  height: 60px;
  line-height: 60px;
  margin: 4px auto 25px;
}

.edit-icon {
  height: 18px;
  object-fit: contain;
  margin-left: 15px;
  cursor: pointer;
}
