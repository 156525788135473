.mm-basic-btn {
  min-width: 80px;
  height: 30px;
}
.mm-basic-btn:hover {
  background-color: #0f614e !important;
  color:'white' !important;
}
.mm-basic-btn-text {
  font-size: 12px;
  font-weight: bold;
  
}
.mm-basic-btn:hover .mm-basic-btn-text {
  color: white;
}
