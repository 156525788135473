.jobs-tab {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100vh;
  flex: 1;
  z-index: 10;
  overflow: scroll;
}

.jobs-tab::-webkit-scrollbar {
  display: none;
}

.infiniteScroll::-webkit-scrollbar {
  display: none;
}

.job-date {
  background-color: #2d3f5a;
  font-size: 13px;
}

.cursor {
  cursor: pointer;
}

//ref no, job status
.col0-width,
.col7-width {
  width: 8%;
}
//job type, truck
.col8-width,
.col1-width {
  width: 7%;
}
//duration
.col3-width {
  width: 17%;
}
//delays
.col5-width {
  width: 15%;
}
//total pump time, water consumed, jobstatus
.col4-width,
.col6-width {
  width: 10%;
}
.col9-width {
  width: 11%;
}
.sub-data {
  color: #707070;
  font-style: italic;
}
.job-history-table-header {
  background-color: #f5f7fa;
  th {
    padding: 10px 10px 30px 10px !important;
  }
}
@media screen and (max-width: 1286px) {
  .job-history-table-header > tr > th:nth-child(2),
  .job-history-table-header > tr > th:nth-child(3),
  .job-history-table-header > tr > th:nth-child(4),
  .job-history-table-header > tr > th:nth-child(5),
  .job-history-table-header > tr > th:nth-child(6),
  .job-history-table-header > tr > th:nth-child(7),
  .job-history-table-header > tr > th:nth-child(8),
  .job-history-table-header > tr > th:nth-child(9),
  .job-history-table-header > tr > th:nth-child(10) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .job-history-table-header > tr > th:nth-child(1) {
    padding-left: 5px !important;
    padding-right: 0 !important;
  }
  .col0-width {
    width: 5%;
  }
  .col1-width {
    width: 5%;
  }
  .col2-width,
  .col3-width,
  .col4-width,
  .col5-width,
  .col6-width,
  .col7-width,
  .col8-width,
  .col9-width,
  .col10-width {
    width: 10%;
  }
}

#job-history-report-container {
  overflow-y: scroll;
  max-height: 70vh;
}
@media (max-height: 900px) {
  #job-history-report-container {
    max-height: 60vh;
  }
}
@media (max-height: 700px) {
  #job-history-report-container {
    max-height: 50vh;
  }
}
#job-history-report-container::-webkit-scrollbar {
  background-color: transparent;
  width: 15px;
}
#job-history-report-container::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 4px solid transparent;
  background-color: rgba(0, 0, 0, 0.2);
  background-clip: content-box;
}
