.engine_hour_table {
  font-size: 13px;
  overflow-y: scroll;
  max-height: 80vh;

  th {
    padding: 8px;
    padding-bottom: 16px;
    vertical-align: middle;
    border-width: 0.5px 0px 0.5px 0px;
    border-color: #ddd;
  }
  td {
    padding: 8px;
    vertical-align: middle;
    border-width: 0.5px 0px 0px 0px;
    border-color: #ddd;
  }
  tbody > tr:hover {
    background-color: #EAF2FF;
    cursor: pointer;
    box-shadow: inset 0px -3px rgba(56, 128, 245, 0.5), inset 0px 2px rgba(56, 128, 245, 0.5);
    border: none;
  }

}

.sticky_header {
  position: sticky;
  top: 0px;
  background: white;
  z-index: 1;
}

.subheader_container {
  display: flex;
  margin-left: -15px;
  padding-bottom: 0.5rem;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 1rem;
}
.text_left {
  text-align: left;
  font-weight: bold;
}

.flex_column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.edit_engine_hour_container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  font-size: 12px;
}

.save_button_container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
}

.hrs_container {
  position: absolute;
  top: 0.7rem;
  right: 0.5rem;
  color: #b8b2b2;
}

.table_footer {
  border-top: 1px solid #e1e8f4;
  font-size: 12px;
}

.actionButton:hover {
  background-color: #82ADF4;
  border-radius: 11px;
  width: 22px;
}

.edit_engine_hour_pop_up {
  font-size: 13px;
}
.edit_engine_hour_pop_up:hover {
  background-color: #EAF2FF;
}

.attention_modal_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  font-size: 12px;
}
