.truckCardsContainer {
    display: flex;
    gap: 40px;
    overflow-x: auto;
    padding: 16px 0px;
    padding-bottom: 5px;
    padding-right: 10px;
    background-color: white;
    border-radius: 8px;
    margin-bottom: 10px;
}

.loader {
    height: 50px;
    width: 50px;
}

.truckCardWrap {
    height: 150px;
    position: relative;
    transition: all 0.5s;
    cursor: pointer;
    flex-grow: 1;
}

.truckCardTxt {
    height: 150px;
    background: #fafafa;
    border: 1px solid #F4F4F4;
    border-radius: 8px;
    transition: all 0.5s;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;

    .label {
        color: #6F7070;
        font-size: 10px;
    }

    .value {
        font-size: 14px;
        font-weight: 600;
    }

    .dozerData {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
}

.truckCardTxtSelected {
    height: 150px;
    transition: all 0.5s;
    background: #363636;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #2B2D2F;
    border-radius: 10px;
    color: #FFFFFF;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;

    .label {
        color: #DDDDDD;
        font-size: 10px;
    }

    .value {
        font-size: 14px;
        font-weight: 600;
    }

    .dozerData {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
}

.img {
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px 8px 4px 4px;
    height: 75px;
    width: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
    position: absolute;
    top: 0px;
    right: 0px;

    img {
        height: 45px;
        width: auto;
        transition: all 0.5s;
    }
}

.imgSelected {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px 8px 4px 4px;
    height: 90px;
    width: 145px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
    position: absolute;
    top: -10px;
    right: -10px;

    img {
        height: 50px;
        width: auto;
        transition: all 0.5s;
    }
}

.title {
    font-weight: 600;
    font-size: 14px;
}

.status {
    font-style: italic;
    font-size: 12px;
}

@media screen and (max-height: 900px) {
    .title {
        font-size: 12px;
    }

    .status {
        font-size: 10px;
    }

    .truckCardWrap {
        height: 130px;
    }

    .truckCardTxt {
        height: 130px;

        .label {
            font-size: 8px;
        }

        .value {
            font-size: 12px;
        }
    }

    .truckCardTxtSelected {
        height: 130px;

        .label {
            font-size: 8px;
        }

        .value {
            font-size: 12px;
        }
    }

    .img {
        height: 70px;
        width: 115px;

        img {
            height: 40px;
        }
    }

    .imgSelected {
        height: 75px;
        width: 125px;

        img {
            height: 45px;
        }
    }

}