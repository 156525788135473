.shift-history-menu {
    color: gray;
    display: flex;
    font-size: 1.2rem;
    flex-direction: row;
    font-weight: 600;
    gap: 30px;
  }
  
  .shift-history-item.active {
    color: #2b7bdc;
    border-bottom: #2b7bdc 2px solid;
  }
  
  .shift-history-toolbar {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: end;
  }
  
  .shift-history-table {
    color: black;
  
    font-size: 1rem;
    width: 100%;
  }
  

  
  .shift-history-table td {
    /* text-align: center; */
    padding-top: 0.75rem;
    padding-bottom: 0rem;
  }
  
  .shift-history-table th:first-child {
    padding-left: 2rem;
  }
  
  .shift-history-table td:last-child {
    padding-right: 2rem;
  }
  
  .mm-shift_green {
    background: #f0fcf5;
    border-left: solid 0.2rem #3ac49f;
  }
  
  .mm-shift_red {

    border-left: 6px solid  #eb5f5f ;
  }  .mm-shift_red_history {

    border-left: 16px solid  #eb5f5f ;
  }
  
  
  .shift-history-hover {
    opacity: 0.5;
  }
  
  .shift-history-table tr:hover .shift-history-hover {
    opacity: 1;
  }
  
  .shift-history-modal {
    background-color: white;
    box-shadow: lightgray 0px 0px 10px 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 100%;
    width: 512px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }
  