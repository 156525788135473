/* 
h1 {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: normal;
  margin-bottom: 1rem;
    color: black;
  font-weight: bold;
} */

.mm-body {
  /* font-size: 1.4rem; */
  line-height: 2.5rem;
  font-weight: 400;
  overflow-x: hidden;
  color: var(--e-global-color-dark-blue);
  scroll-behavior: smooth;
  font-size: 75%;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
}

*:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background: var(--e-global-before-light-border-color);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #8c9cba;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #8c9cba;
  border-radius: 10px;
}

:root {
  --body-font-family: "Open Sans", sans-serif;
  --e-global-color-white: #fff;
  --e-global-color-black: #000;
  --e-global-before-login-background: #fafbfd;
  --e-global-color-dark-blue: #092344;
  --e-global-color-blue-button-color: #186fd9;
  --e-global-before-sidebar-background: #07273f;
  --e-global-before-sidebar-text-color: #bdc5d0;
  --e-global-before-rightbar-text-color: #1a1a1a;
  --e-global-before-light-border-color: #ebebeb;
  --e-global-before-dark-green: #26a071;
  --e-global-before-dark-red: #cb4d42;
}

.mm-loadingScreen {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.2);
  z-index: 99999;
}
.mm-loadingScreen .spinner-grow {
  width: 4rem !important;
  height: 4rem !important;
}
.mm-loadingScreen .spinner-border {
  width: 4rem !important;
  height: 4rem !important;
}
img {
  max-width: 100%;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

a,
a:hover {
  text-decoration: none;
}

input:focus,
select:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: var(--e-global-color-blue-button-color) !important;
}

.mm-before_login_screen {
  width: 100vw;
  min-height: 100vh;
  padding: 5rem;
  background: var(--e-global-before-login-background);
  display: flex;
  align-items: center;
  justify-content: center;
}

.before_main_mm-login_box {
  width: auto;
  height: auto;
}

.mm-before_logo {
  width: 15rem;
  text-align: center;
  margin: auto auto 4rem;
}

.mm-login_box {
  width: 55rem;
  height: auto;
  padding: 2rem;
  border-radius: 1rem;
  background: var(--e-global-color-white);
  box-shadow: 0px 6px 20px #00000029;
}

.mm-before_login_heading {
  width: 100%;
  padding: 0 1.5rem;
  margin-bottom: 4rem;
}

.mm-before_login_heading p {
  color: #677789;
}

.mm-login_box form {
  width: 100%;
  height: auto;
}

.mm-login_box .form-group,
.mm-generate_new_pin_form .form-group {
  margin-bottom: 3rem;
  position: relative;
}

.mm-login_box .form-group .mm-input_field_wrap,
.mm-generate_new_pin_form .mm-input_field_wrap {
  width: 100%;
  padding: 0;
  border-radius: 0.4rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  position: relative;
}

.mm-login_box .form-group .mm-input_field_wrap label,
.mm-generate_new_pin_form .form-group .mm-input_field_wrap label {
  font-size: 1.4rem !important;
  font-weight: 500;
  text-transform: capitalize;
  color: var(--e-global-color-black);
  position: absolute;
  left: 1.5rem;
  cursor: auto;
  letter-spacing: 0.05rem;
  transition: all 0.2s;
}

.mm-login_box .form-group .mm-input_field_wrap input,
.mm-generate_new_pin_form .mm-input_field_wrap input {
  width: 100%;
  height: 5.5rem;
  padding: 0 1.5rem;
  font-size: 1.4rem;
  color: var(--e-global-color-black);
  border: none;
  background: transparent;
  position: relative;
  z-index: 2;
  border: solid 0.1rem #dee5f2;
  background: transparent !important;
}

.mm-login_box .mm-form-control-message {
  left: 0;
}

/* .mm-login_box .form-group .mm-input_field_wrap input:focus::placeholder,  .mm-popup_animated_form input:focus::placeholder {
	opacity: 0;
} */

.mm-login_box .form-group input::placeholder,
.mm-generate_new_pin_form .form-group input::placeholder {
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: capitalize;
  color: var(--e-global-color-black);
}

.mm-onpwd {
  position: absolute;
  top: 2rem;
  right: 1rem;
  /* bottom: 0; */
  margin: auto;
  height: 2.5rem;
  z-index: 3;
  cursor: pointer;
}

.mm-onpwd i {
  color: #dee5f2;
}

.mm-login_box .form-group .login_button {
  background: var(--e-global-color-blue-button-color);
  color: var(--e-global-color-white);
  border-color: var(--e-global-color-blue-button-color);
  font-size: 1.6rem;
  font-weight: 600;
  height: 5rem;
}

.mm-login_box .mm-forgot_password {
  text-align: center;
}

.mm-login_box .mm-forgot_password a {
  font-weight: bold;
  color: var(--e-global-color-blue-button-color);
}

.mm-before_login_footer_menu {
  width: 100%;
  margin-top: 5rem;
}

.mm-before_login_footer_menu ul {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mm-before_login_footer_menu ul li {
  padding: 0 1.5rem;
  line-height: 1.6rem;
  font-weight: 500;
  font-size: 1.6rem;
}

.mm-before_login_footer_menu ul li:not(:last-child) {
  border-right: solid 0.1rem #5f6f8d;
}

.mm-before_login_footer_menu ul li a {
  color: var(--e-global-color-blue-button-color);
}

/*--- manage user css start ---*/

.mm-manage_dashboard {
  width: 100%;
  min-height: 100vh;
  display: block;
  background: var(--e-global-before-login-background);
  overflow-y: hidden;
}

.mm-left_sidebar {
  width: 22rem;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  transition: 0.2s;
  background: #2b2d2f;
}

.mm-hide_sidebar {
  width: 0;
}

.mm-hide_sidebar .mm-mm-left_sidebar_top,
.mm-hide_sidebar .mm-sidebar_menu {
  padding: 0;
}

.mm-hide_sidebar .mm-sidebar_menu {
  display: none;
}

.mm-mm-left_sidebar_top {
  width: 100%;
  padding: 2rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mm-dashboard_logo {
  width: 10rem;
}

.mm-dashboard_logo a {
  display: block;
}

.mm-dashboard_logo a img {
  max-width: 100%;
}

.mm-toggle_design {
  width: 23px;
  height: 12px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  cursor: pointer;
  margin-bottom: 50px;
}

.mm-toggle_design span {
  width: 100%;
  height: 0.2rem;
  display: inline-block;
  margin: 0.25rem 0;
  background: var(--e-global-before-sidebar-text-color);
}

.mm-toggle_design span:first-child {
  margin-top: 0;
}

.mm-toggle_design span:last-child {
  margin-bottom: 0;
}

.mm-toggle_design:before {
  content: "<";
  position: absolute;
  left: -1.7rem;
  top: -0.4rem;
  font-size: 1.2rem;
  color: var(--e-global-before-sidebar-text-color);
}

.mm-hide_sidebar .mm-toggle_design {
  position: absolute;
  right: -5rem;
  top: 57%;
}

.mm-hide_sidebar .mm-toggle_design:before {
  content: "";
  color: var(--e-global-before-rightbar-text-color);
  background-image: url(../images/menu.svg);
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  display: block;
  background-size: 100% auto;
  top: 0.4rem;
}

.mm-hide_sidebar .mm-toggle_design span {
  display: none;
}

.mm-sidebar_menu {
  width: 100%;
  padding: 2rem 0;
  border-color: var(--e-global-before-sidebar-text-color);
}

.mm-sidebar_menu h6 {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--e-global-color-white);
}

.mm-sidebar_menu ul li {
  width: 100%;
  padding: 0.6rem 2rem;
}

.mm-sidebar_menu ul li a {
  color: var(--e-global-before-sidebar-text-color);
}
.mm-sidebar_menu ul li.active a {
  color: var(--e-global-color-white);
  font-weight: 600;
}

.mm-top_border {
  border-width: 0.1rem 0 0 0;
  border-style: solid;
}

.mm-right_sidebar {
  height: 100vh;
  width: 100%;
  transition: 0.1s;
}

.mm-full_dashboard {
  width: 100%;
  margin: 0;
}

.mm-top_header {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 998;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0.7rem 3rem; */
  /* box-shadow: 0 0.1rem 0.3rem #00000029; */
  background: var(--e-global-color-white);
}
.mm-top_header-1 {
  width: 100%;
  position: sticky;
  top: 0;
  /* z-index: 997; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.7rem 0rem;
  /* box-shadow: 0 0.1rem 0.3rem #00000029; */
  background: var(--e-global-color-white);
}

.mm-mm-top_header_2 {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 998;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0.7rem 3rem; */
}
.mm-full_dashboard .mm-top_header {
  padding: 1rem 3rem 1rem 5rem;
}

.mm-top_heading h2 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: bold;
  text-transform: capitalize;
  color: var(--e-global-before-rightbar-text-color);
}

.mm-top_right ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mm-top_right ul li {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: #f0f4fb;
  margin-left: 1rem;
  border: solid 0.1rem var(--e-global-before-light-border-color);
}

.mm-top_right ul li a {
  width: 100%;
  height: 100%;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--e-global-before-rightbar-text-color);
}

.mm-right_sidebar_body {
  height: calc(100% - 3.5rem);
  width: 100%;
  overflow: none;
}

.mm-custom-cursor {
  cursor: url("https://img.icons8.com/material-two-tone/24/pencil.png") 2 22,
    auto !important;
  /* cursor: url("./pencil.png"); */
}

.mm-btn-main {
  font: normal normal normal 12px/14px Open Sans;
  float: left;
  height: 2.5rem;
  width: max-content;
  display: flex;
  padding-left: 0.66rem;
  padding-right: 0.66rem;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 4px #00000029;
  border-radius: 0.33rem;
  cursor: pointer;
  font-size: 1.1em;
}

#mm-my-tooltip-data-html {
  width: max-content !important;
}

.mm-header_dropdown_li {
  position: relative;
}
.mm-header_dropdown_li ul {
  flex-direction: column;
}
.mm-header_dropdown_li ul.custom_dropdown li {
  border-radius: 0px;
  background-color: transparent;
  border: none;
  margin: 0;
}

.mm-top_middle {
  width: 100%;
  margin-bottom: 3rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.mm-mm-top_middle_left a {
  padding: 0.7rem 2.5rem;
  display: block;
  border-radius: 0.5rem;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--e-global-color-white);
  background: var(--e-global-color-blue-button-color);
}

.mm-mm-top_middle_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mm-mm-top_middle_search {
  display: flex;
  overflow: hidden;
  border-radius: 2.5rem;
  padding-left: 1.5rem;
  margin-right: 1.5rem;
  background: var(--e-global-color-white);
  border: solid 0.2rem var(--e-global-before-light-border-color);
}

.mm-mm-top_middle_search input {
  width: 18rem;
  height: 4rem;
  border: none;
}

.mm-mm-top_middle_search input::placeholder {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--e-global-before-rightbar-text-color);
}

.mm-mm-top_middle_search button {
  width: 4rem;
  border: none;
  font-size: 2rem;
  background: var(--e-global-color-white);
}

.mm-mm-top_middle_search button:focus {
  outline: none;
}

.mm-mm-top_middle_filter {
  width: 4rem;
}

.mm-filter_popup {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}

.mm-filter_popup .mm-react-datepicker-wrapper,
.mm-filter_popup .filter-box {
  margin-right: 15px;
}

.mm-filter_popup input,
.mm-filter_popup select {
  width: 100%;
  height: 4rem;
  border: solid 0.1rem var(--e-global-before-light-border-color);
  border-radius: 0.5rem;
}

.mm-select_check_user {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 2rem;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--e-global-before-login-background);
}

.mm-mm-select_check_user_left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.mm-mm-select_check_user_left button {
  padding: 0.6rem 2.5rem;
  display: block;
  border-radius: 0.5rem;
  font-weight: 600;
  margin-right: 1rem;
  text-transform: capitalize;
  color: var(--e-global-color-white);
  background: var(--e-global-color-blue-button-color);
  border: solid 0.2rem var(--e-global-color-blue-button-color);
}

.mm-mm-select_check_user_left button.border_button {
  border: solid 0.2rem black;
  background: var(--e-global-color-white);
  font-weight: 700;
  color: var(--e-global-color-blue-button-color);
}

.mm-mm-select_check_user_right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mm-mm-select_check_user_right p {
  font-weight: 500;
  margin: 0;
}

.mm-table_main {
  width: 100%;
  min-height: 70vh;
  position: relative;
  border-radius: 0.5rem;
  background: var(--e-global-color-white);
  border: solid 0.1rem var(--e-global-before-light-border-color);
  overflow: hidden;
}

/* .mm-table_main .infinite-scroll-component__outerdiv {
	position: absolute;
	width: 100%;
	top: 4.5rem;
	left: 0;
} */

.mm-table_main table {
  width: 100%;
  border-radius: 0.5rem;
}

.mm-table_main table thead {
  background: #f2f7ff;
}

.mm-table_main table tr {
  border-bottom: solid 0.1rem var(--e-global-before-light-border-color);
}

.mm-table_main table thead th {
  font-weight: bold;
}

.mm-table_main table thead th,
.mm-table_main table tbody td {
  padding: 1rem;
  font-size: 1.1rem;
  line-height: normal;
}

.mm-table_main .status {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  margin-right: 0.4rem;
  display: inline-block;
}

.status.inactive {
  background: #cb4d42;
}

.status.invited {
  background: #e6a901;
}

.status.active {
  background: #38c378;
}

.mm-table_manage_user table tr td .table_text_blue {
  font-weight: 700;
  color: var(--e-global-color-blue-button-color);
}

.mm-table_manage_user table thead th:first-child,
.mm-table_manage_user table tbody td:first-child {
  width: 4%;
  height: 1px;
  padding: 0;
}

.mm-table_manage_user table thead th:nth-child(2),
.mm-table_manage_user table tbody td:nth-child(2) {
  width: 12%;
}

.mm-table_manage_user table thead th:nth-child(3),
.mm-table_manage_user table tbody td:nth-child(3) {
  width: 8%;
}

.mm-table_manage_user table thead th:nth-child(4),
.mm-table_manage_user table tbody td:nth-child(4) {
  width: 9%;
}

.mm-table_manage_user table thead th:nth-child(5),
.mm-table_manage_user table tbody td:nth-child(5) {
  width: 17%;
}

.mm-table_manage_user table thead th:nth-child(6),
.mm-table_manage_user table tbody td:nth-child(6) {
  width: 12%;
}

.mm-table_manage_user table thead th:nth-child(7),
.mm-table_manage_user table tbody td:nth-child(7) {
  width: 7%;
}

.mm-table_manage_user table thead th:nth-child(8),
.mm-table_manage_user table tbody td:nth-child(8) {
  width: 6%;
}

.mm-table_manage_user table thead th:nth-child(9),
.mm-table_manage_user table tbody td:nth-child(9) {
  width: 15%;
}

.mm-table_manage_user table thead th:nth-child(10),
.mm-table_manage_user table tbody td:nth-child(10) {
  width: 10%;
  text-align: center;
}

.mm-table_manage_user table thead th:nth-child(11),
.mm-table_manage_user table tbody td:nth-child(11) {
  width: 6%;
}

.mm-all_popup {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
}

.mm-add_new_user_box {
  width: 100%;
  max-width: 676px;
  max-height: max-content;
  /* overflow-y: auto; */
  /* position: absolute; */
  top: 0;
  right: 0;
  float: right;
  padding: 2rem 2rem;
  border: solid 0.1rem var(--e-global-before-light-border-color);
  background: var(--e-global-color-white);
  box-shadow: 0px 10px 14px #0000001a;
  /* overflow: auto; */
  /* display: none; */
  /* height: calc(100vh - 16rem); */
  margin-top: 16rem;
}
.mm-add_new_user_boxInner {
  height: auto;
}

.mm-add_new_user_boxInner::-webkit-scrollbar {
  display: none;
}

.mm-active_user {
  display: block !important;
}

.mm-add_new_user_box_top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mm-modalDone {
  cursor: pointer;
  margin-right: 10px;
}
.mm-modalClose {
  cursor: pointer;
}
.mm-add_new_user_box_mm-top_heading h3 {
  font-size: 1.6rem;
  font-weight: bold;
  margin: 0;
}

.mm-add_new_user_box_mm-top_right button {
  min-width: 10rem;
  height: auto;
  text-align: center;
  padding: 0.8rem 0.6rem;
  border: solid 0.1rem;
  border-radius: 0.4rem;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: capitalize;
}

.mm-add_new_user_box_mm-top_right .discard {
  border-color: #b3c8ed;
  color: var(--e-global-color-blue-button-color);
  background: var(--e-global-color-white);
  margin-right: 0.5rem;
  border-radius: 4px;
  padding: 0.8rem 2rem;
}

.mm-add_new_user_box_mm-top_right .save {
  border-color: var(--e-global-color-blue-button-color);
  color: var(--e-global-color-white);
  background: var(--e-global-color-blue-button-color);
}

.mm-add_new_user_box_mm-top_right button:focus {
  outline: none;
  box-shadow: none;
}

.mm-add_new_user_box_middle {
  width: 100%;
  margin-top: 1rem;
  /* height: 435px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 1rem; */
}

.mm-add_new_user_box_middle .form-group {
  margin-bottom: 1.2rem;
}

.mm-add_new_user_box_middle .form-group .mm-form-control-message {
  position: relative;
  left: 0;
  width: 100%;
  float: left;
}

.mm-add_new_user_box_middle .form-group .col-md-4 {
  display: flex;
  align-items: center;
}

.mm-add_new_user_box_middle .form-group label {
  font-size: 1.3rem;
  font-weight: 500;
  margin: 0;
  display: flex;
  align-items: center;
  height: 100%;
}

.mm-add_new_user_box_middle .form-group input,
.mm-add_new_user_box_middle .form-group select {
  width: 100%;
  padding: 0 1rem;
  font-weight: 600;
  height: 4rem;
  border-radius: 0.4rem;
  border: solid 0.1rem var(--e-global-before-light-border-color);
}

.mm-add_new_user_box_middle .form-group input[type="number"] {
  background: #fff;
}

.mm-add_new_user_box_middle .form-group input:disabled {
  background: #e1e4ea;
  cursor: not-allowed;
}

.mm-add_new_user_box_middle .form-group select:focus {
  outline: none;
  box-shadow: none;
}

.mm-action_popup {
  width: 35%;
  position: absolute;
  top: 0;
  right: 0;
  border: solid 0.1rem var(--e-global-before-light-border-color);
  background: var(--e-global-color-white);
  box-shadow: 0px 10px 14px #0000001a;
  display: none;
  height: 100%;
}

.mm-action_popup_top {
  width: 100%;
  padding: 2rem 3rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 0.1rem var(--e-global-before-light-border-color);
}

.mm-action_popup_mm-top_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mm-status_activation {
  position: relative;
  width: 9rem;
  height: 3rem;
}

.mm-switch {
  display: inline-block;
  width: 9rem;
  height: 3rem;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.mm-status_activation .mm-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.mm-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--e-global-before-dark-red);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--e-global-color-white);
  padding-left: 0;
  padding-right: 1rem;
}

.mm-switch .slider:before {
  position: absolute;
  content: "";
  height: 2rem;
  width: 2rem;
  left: 0.7rem;
  bottom: 0;
  top: 0;
  margin: auto;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.mm-switch input:checked + .slider {
  background-color: var(--e-global-before-dark-green);
  justify-content: flex-start;
  padding-left: 1rem;
  padding-right: 0;
}

.mm-switch input:focus + .slider {
  box-shadow: 0 0 1px var(--e-global-before-dark-green);
}

.mm-switch input:checked + .slider:before {
  -webkit-transform: translateX(5.6rem);
  -ms-transform: translateX(5.6rem);
  transform: translateX(5.6rem);
}

.mm-switch .slider.round {
  border-radius: 3.4rem;
}

.mm-switch .slider.round:before {
  border-radius: 50%;
}

.mm-action_popup_mm-top_right_dotted {
  margin: 0 2rem;
  position: relative;
}

.mm-action_popup_mm-top_right_dotted i {
  font-size: 1.8rem;
}

.mm-action_popup_mm-top_right_dotted .dotted_listing {
  width: 12rem;
  position: absolute;
  top: 105%;
  right: 0;
  z-index: 9;
  border-radius: 0;
  box-shadow: 0.1rem 0.4rem 1rem #00000029;
  background: var(--e-global-color-white);
  border: solid 0.1rem var(--e-global-before-light-border-color);
}

.mm-action_popup_mm-top_right_dotted .dotted_listing ul li {
  padding: 0.5rem 1rem;
}

.mm-action_popup_middle {
  width: 100%;
  padding: 2rem 4rem;
}

.mm-action_popup_middle span {
  font-size: 1.4rem;
  text-transform: capitalize;
}

.mm-action_popup_middle label {
  font-size: 1.4rem;
  font-weight: 700;
  margin: 0;
  text-transform: capitalize;
  word-break: break-word;
}
.mm-action_popup_middle label.emailLabel {
  text-transform: lowercase;
}

.mm-generate_new_pin {
  justify-content: center;
  text-align: center;
  border-radius: 0.5rem;
  font-weight: 700;
  font-size: 1.4rem;
  padding: 0 1rem;
  cursor: pointer;
  color: var(--e-global-color-blue-button-color);
  border: solid 0.1rem var(--e-global-before-light-border-color);
}

.mm-full_modal {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(7, 22, 41, 0.8);
  display: none;
}

.mm-generate_new_pin_active {
  display: flex;
}

.mm-generate_new_pin_box {
  width: 35rem;
  padding: 4rem;
  border-radius: 1rem;
  position: relative;
  text-align: center;
  box-shadow: 0px 6px 20px #00000029;
  background: var(--e-global-color-white);
}

.mm-generate_new_pin_box h4 {
  font-size: 2rem;
  font-weight: 700;
  /* text-transform: capitalize; */
  color: var(--e-global-color-dark-blue);
}

.mm-generate_new_pin_form {
  margin-top: 2rem;
}

.mm-generate_new_pin_form .mm-add_new_user_box_mm-top_right {
  margin-top: 2rem;
}

.mm-user_profile_popup {
  width: 15rem;
  display: none;
  position: absolute;
  top: 100%;
  right: 0rem;
  top: 60px;
  border-radius: 0.4rem;
  z-index: 999;
  box-shadow: 1px 3px 4px #00000029;
  background: var(--e-global-color-white);
  border: solid 0.1rem var(--e-global-before-light-border-color);
}

.mm-user_profile_popup ul li {
  width: 100%;
  text-transform: capitalize;
}

.mm-user_profile_popup ul li:hover {
  background: var(--e-global-before-light-border-color);
}

.mm-user_profile_popup ul li a {
  padding: 0.5rem 1rem;
  display: block;
  width: 100%;
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--e-global-before-rightbar-text-color);
  text-transform: initial;
  display: flex;
  justify-content: flex-start;
}

/*--- My Account Page ---*/

.mm-my_account_page {
  width: 100%;
  height: calc(100vh - 13rem);
  display: flex;
  align-items: center;
  justify-content: center;
}

.mm-my_account_box {
  width: 53.4rem;
  text-align: left;
  padding: 5rem 5.8rem;
}

.mm-my_account_box .col-md-3 {
  padding: 0;
}

.mm-popup_animated_form input {
  border: none;
  text-align: left;
}

.mm-popup_animated_form .mm-add_new_user_box_mm-top_right {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mm-my_account_box h4 {
  text-align: center;
  margin-bottom: 3rem;
}

/*  shift planning style */

.mm-shit_top {
  width: 100%;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.mm-shit_top ul {
  display: flex;
  width: calc(100% - 0rem);
  border-bottom: solid 0.1rem var(--e-global-before-light-border-color);
}

.mm-shit_top ul li {
  font-size: 1.4rem;
  font-weight: 600;
  padding: 0 1.5rem;
  color: #6a7c9a;
}

.mm-shit_top ul li:first-child {
  padding-left: 0;
}

.mm-shit_top ul li a {
  color: #6a7c9a;
  display: block;
  padding: 0 0 0.5rem;
  border-bottom: solid 0.2rem transparent;
}

.mm-shit_top ul li.active a {
  border-color: var(--e-global-color-blue-button-color);
  color: var(--e-global-color-blue-button-color);
}

.mm-daily_button {
  margin-left: 1rem;
}

.mm-daily_button a {
  padding: 0.7rem 2.5rem;
  display: block;
  border-radius: 0.5rem;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--e-global-color-white);
  background: var(--e-global-color-blue-button-color);
}

.mm-table_shift_plan,
.mm-table_shift_plan .infinite-scroll-component {
  overflow: inherit !important;
}

.mm-table_shift_plan table thead {
  position: sticky;
  top: 78px;
  z-index: 99;
}
.mm-table_shift_plan_listing tbody tr.active {
  border: solid 0.3rem #186fd9;
}
.mm-table_shift_plan_listing table thead::before {
  content: "";
  display: block;
  width: 100%;
  height: 7rem;
  position: absolute;
  left: 0;
  top: 0;
  background: #f2f7ff;
  z-index: -1;
  /* border-bottom: solid 0.1rem var(--e-global-before-light-border-color); */
}
.mm-table_shift_plan table thead tr th {
  border: solid 0.1rem var(--e-global-before-light-border-color) !important;
  position: relative;
}
.mm-table_shift_plan_listing table thead tr th {
  border: none;
}
.mm-table_shift_plan_listing table thead tr th::before {
  content: "";
  display: block;
  width: 100%;
  height: 7rem;
  position: absolute;
  left: 0;
  top: 0;
  border-right: solid 0.1rem var(--e-global-before-light-border-color);
  /* border-bottom: solid 0.1rem var(--e-global-before-light-border-color); */
  z-index: -1;
}
.mm-table_shift_plan_listing table thead tr:last-child th::before {
  border-right: solid 0.1rem var(--e-global-before-light-border-color);
  border-top: solid 0.1rem var(--e-global-before-light-border-color);
  border-bottom: none;
  height: 3.4rem;
}
.mm-table_shift_plan table thead tr th.border-bottom-none::before {
  border-bottom: none;
}
.mm-table_shift_plan table tr th,
.mm-table_shift_plan table tr td {
  padding: 1rem 1.5rem;
}
.mm-table_shift_plan tr.left_green td:first-child {
  position: relative;
}
.mm-table_shift_plan tr.left_green td:first-child::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  width: 3px;
  height: 100%;
  background-color: green;
  display: block;
}

/* .mm-table_shift_plan table thead th:first-child,
.mm-table_shift_plan table tbody td:first-child {
  width: 7%;
}

.mm-table_shift_plan table thead th:nth-child(2),
.mm-table_shift_plan table tbody td:nth-child(2) {
  width: 5%;
}

.mm-table_shift_plan table thead th:nth-child(3),
.mm-table_shift_plan table tbody td:nth-child(3) {
  width: 7%;
}

.mm-table_shift_plan table thead th:nth-child(4),
.mm-table_shift_plan table tbody td:nth-child(4) {
  width: 7%;
}

.mm-table_shift_plan table thead th:nth-child(5),
.mm-table_shift_plan table tbody td:nth-child(5) {
  width: 5%;
}

.mm-table_shift_plan table thead th:nth-child(6),
.mm-table_shift_plan table tbody td:nth-child(6) {
  width: 7%;
}

.mm-table_shift_plan table thead th:nth-child(7) {
  width: 8%;
  text-align: center;
}

.mm-table_shift_plan table tbody td:nth-child(7) {
	width: 4%;
}

.mm-table_shift_plan table thead th:nth-child(8) {
  width: 8%;
  text-align: center;
}

.mm-table_shift_plan table tbody td:nth-child(8) {
	width: 4%;
}

.mm-table_shift_plan table thead th:nth-child(9) {
  width: 13%;
}

.mm-table_shift_plan table tbody td:nth-child(9) {
	width: 4%;
}

.mm-table_shift_plan table thead th:nth-child(10) {
  width: 20%;
  text-align: center;
}

.mm-table_shift_plan table tbody td:nth-child(10) {
	width: 4%;
}

.mm-table_shift_plan table thead th:nth-child(11) {
  width: 13%;
}

.mm-table_shift_plan table tbody td:nth-child(11) {
	width: 13%;
}

.mm-table_shift_plan table tbody td:nth-child(12) {
	width: 20%;
}

.mm-table_shift_plan table tbody td:nth-child(13) {
	width: 10%;
}

.mm-table_shift_plan table tbody td:nth-child(14) {
	width: 3%;
} */

.mm-table_shift_plan table thead th:nth-child(10) {
  width: 15%;
}

.mm-table_shift_plan table tbody td:nth-child(12) {
  width: 15%;
}

.mm-table_shift_plan table tbody td:nth-child(14) {
  width: 3%;
  text-align: center;
}

.mm-table_shift_plan table thead tr th:nth-last-child(2) {
  border-right: none !important;
}

.mm-table_shift_plan table thead tr th:last-child {
  border-left: none !important;
}

.mm-table_shift_plan table tbody td {
  white-space: break-spaces !important;
  word-break: break-all !important;
  width: 14rem;
}

.mm-shift_green {
  background: #f0fcf5;
  border-left: solid 0.2rem #3ac49f;
}

.mm-shift_red {
  background: #ffeded;
  border-left: solid 3px #eb5f5f;
}

.ongoing {
  color: #26a071;
}

.published {
  color: #186fd9;
}

.draft {
  color: #e58d00;
}

.ended {
  color: #6a7c9a;
}

.mm-mm-new_daily_plan_heading h3 i {
  cursor: pointer;
}
.mm-mm-new_daily_plan_heading h3 {
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--e-global-color-black);
}

.mm-new_daily_plan table {
  border-color: var(--e-global-before-light-border-color);
}

.mm-new_daily_plan .mm-shift_table tr td {
  padding: 0.8rem 1.5rem !important;
  border-right: solid 0.1rem var(--e-global-before-light-border-color) !important;
}
.mm-new_daily_plan .mm-shift_table tr td:last-child {
  border: none !important;
}

.mm-new_daily_plan .mm-shift_table tr td span {
  width: 7.5rem;
  display: inline-block;
  max-width: 7.5rem;
  vertical-align: top;
}
.mm-new_daily_plan .mm-shift_table tr td b {
  width: calc(100% - 7.5rem);
  display: inline-block;
  vertical-align: top;
}

.mm-shift_table tr,
.mm-shift_table tr td {
  border: none !important;
  padding: 1rem 1rem !important;
  font-size: 1.2rem !important;
}

.mm-mm-new_daily_plan_heading .mm-daily_button a {
  border: solid 0.2rem var(--e-global-color-blue-button-color);
  background: var(--e-global-color-white);
  font-weight: 700;
  color: var(--e-global-color-blue-button-color);
}

.excavators table tr:nth-child(even) {
  background: #f8fafd;
}

/* .excavators table tr td p{
  height: 3.2rem;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.mm-custom_radio {
  display: block;
  position: relative;
  padding-left: 3.5rem;
  margin-right: 1.5rem !important;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mm-custom_radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.mm-custom_radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  background-color: var(--e-global-color-white);
  border: solid 0.1rem var(--e-global-before-light-border-color);
}

.mm-custom_radio:hover input ~ .checkmark {
  background-color: var(--e-global-color-white);
}

.mm-custom_radio input:checked ~ .checkmark {
  background-color: var(--e-global-color-white);
  border-color: var(--e-global-color-blue-button-color);
}

.mm-custom_radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.mm-custom_radio input:checked ~ .checkmark:after {
  display: block;
}

.mm-custom_radio .checkmark:after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background: var(--e-global-color-blue-button-color);
}

.mm-new_daily_plan_popup .mm-add_new_user_box_middle .mm-shift_tab_list {
  width: 100%;
  margin-bottom: 2rem;
}

.mm-new_daily_plan_popup .mm-add_new_user_box_middle .mm-shift_tab_list ul {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid var(--e-global-before-light-border-color);
}

.mm-new_daily_plan_popup .mm-add_new_user_box_middle .mm-shift_tab_list ul li {
  padding: 0 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: capitalize;
}
.mm-new_daily_plan_popup
  .mm-add_new_user_box_middle
  .mm-shift_tab_list
  ul
  li.active
  a {
  color: #186fd9;
  border-bottom: 2px solid #186fd9;
}

.mm-new_daily_plan_popup
  .mm-add_new_user_box_middle
  .mm-shift_tab_list
  ul
  li.mm-shift_tab_list_active
  a {
  color: var(--e-global-color-blue-button-color);
  border-color: var(--e-global-color-blue-button-color);
}

.mm-new_daily_plan_popup
  .mm-add_new_user_box_middle
  .mm-shift_tab_list
  ul
  li
  a {
  color: #6a7c9a;
  padding: 0.5rem 0;
  display: block;
  border-bottom: solid 0.2rem transparent;
}

.mm-new_daily_plan_popup
  .mm-add_new_user_box_middle
  .mm-shift_tab_list
  ul
  li:first-child {
  padding-left: 0;
}

.mm-new_daily_plan_popup
  .mm-add_new_user_box_middle
  .mm-shift_tab_list
  ul
  li:last-child {
  padding-right: 0;
}

.big_table {
  width: auto;
  overflow-x: auto;
}

.big_table table tr th,
.big_table table tr td {
  min-width: 14rem;
  max-width: max-content;
  white-space: nowrap;
}

/* Extra style entered */

.mm-form-control-message {
  color: red;
  text-align: left;
  display: block;
  position: absolute;
  left: 15px;
  bottom: 0;
  font-size: 1rem;
}

/*  */

.mm-close_icon {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  cursor: pointer;
}

.mm-action_buttons_add_daily_plan {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.mm-action_buttons_add_daily_plan .form-control {
  position: fixed;
  top: 10px;
  width: 100%;
  max-width: 250px;
  right: 12%;
  z-index: 9999;
}

.mm-blue_button a {
  color: var(--e-global-color-white) !important;
  background: var(--e-global-color-blue-button-color) !important;
}

.mm-mm-addDailyPlanTablemain {
  position: relative;
}
.mm-mm-addDailyPlanTablemain .mm-all_popup {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.mm-mm-addDailyPlanTablemain .mm-all_popup .mm-add_new_user_box {
  margin-top: 0px;
  position: absolute;
  min-height: 124rem;
}
.mm-mm-addDailyPlanTablemain
  .mm-all_popup
  .mm-add_new_user_box
  .mm-add_new_user_box_middle {
  height: calc(100% - 40px);
}
.mm-mm-addDailyPlanTablemain
  .mm-all_popup
  .mm-add_new_user_box
  .mm-add_new_user_box_middle
  .mm-modal_scroll {
  height: initial;
  max-height: initial;
  overflow-y: auto;
  overflow-x: hidden;
}
.mm-mm-addDailyPlanTablemain .title {
  background-color: #f8fafd;
  padding: 1.5rem 0.7rem;
  border: solid 0.1rem var(--e-global-before-light-border-color);
}
.mm-mm-addDailyPlanTablemain .title p {
  font-size: 1.1rem;
  line-height: normal;
  color: var(--e-global-color-dark-blue);
  font-weight: 700;
  margin: 0;
}
.mm-addDailyPlanTable {
  margin-left: 14rem;
}
.mm-addDailyPlanTable table {
  border: none;
}
.mm-addDailyPlanTable table tr td {
  height: 74px;
  border-left: none;
  border-top: none;
  text-align: center;
  white-space: nowrap !important;
}
.mm-addDailyPlanTable
  table
  tr
  td:last-child
  .mm-customTooltip
  .mm-customTooltip-show {
  /* display: block; */
  right: 0;
  left: initial;
  transform: translate(0px, 0px);
}
.mm-addDailyPlanTable
  table
  tr
  td:last-child
  .mm-customTooltip
  .mm-customTooltip-show::after {
  left: 80%;
}
/* .mm-addDailyPlanTable table tr:first-child td{
  height: 4rem;
} */
.mm-addDailyPlanTable table tr td:first-child {
  position: absolute;
  left: 0;
  z-index: 5;
}
.mm-addDailyPlanTable table tr td:first-child .mm-ellipse_dropdown {
  position: absolute;
}

.mm-addDailyPlanTable table tr td:first-child .mm-ellipse_dropdown ul {
  right: initial;
  left: 14rem;
  z-index: 999;
  top: 0;
  min-width: 15rem;
  padding: 1rem;
}
.mm-addDailyPlanTable table tr td:first-child .mm-ellipse_dropdown ul li {
  cursor: pointer;
}
.mm-addDailyPlanTable table tr:nth-child(even) td:first-child {
  background: #fff;
}
.mm-addDailyPlanTable table tr:nth-child(even) td:first-child {
  background: #f8fafd;
}
.excavators.mm-addDailyPlanTable table tr:nth-child(n + 2) td:first-child p {
  padding: 0.5rem 1rem 1rem;
  border-right: solid 0.1rem var(--e-global-before-light-border-color);
  margin: 0;
  height: 42px;
  white-space: normal;
  width: 14rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mm-customTooltip {
  position: relative;
}
.mm-customTooltip p {
  white-space: normal;
  width: 14rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  word-break: break-word !important;
}
.mm-customTooltip .mm-customTooltip-show {
  display: none;
  position: absolute;
  bottom: 100%;
  background: var(--e-global-before-sidebar-background);
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  white-space: normal;
  width: max-content;
  max-width: 500px;
  left: 50%;
  transform: translate(-50%, 0px);
}
.mm-customTooltip .mm-customTooltip-show::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--e-global-before-sidebar-background) transparent
    transparent transparent;
}
.mm-customTooltip:hover .mm-customTooltip-show {
  display: block;
}
.mm-customTooltip:hover .mm-customTooltip-show:empty {
  display: none;
}
.mm-customTooltip:hover .mm-customTooltip-show:empty::after {
  display: none;
}
.excavators.mm-addDailyPlanTable
  table
  tr:nth-child(n + 2)
  td:first-child
  .exc_number {
  border-right: solid 0.1rem var(--e-global-before-light-border-color);
}

.mm-calendarPopup {
  background: #ffffff;
  box-shadow: -1px 1px 6px #0000001a;
  border-radius: 10px 0px 0px 10px;
  padding: 2rem 2rem;
  width: 100%;
  max-width: 290px;
  position: absolute;
  right: 676px;
  z-index: 999;
  top: 0rem;
  display: none;
}
.mm-calendarPopup.show {
  display: block;
}
.mm-calendarPopup p {
  font-size: 1.6rem;
  font-weight: bold;
  margin: 0 0 15px;
  color: var(--e-global-color-dark-blue);
}
.mm-calendarPopup .mm-react-datepicker {
  width: 100%;
  border: none;
}
.mm-calendarPopup .mm-react-datepicker__month-container {
  width: 100%;
}
.mm-calendarPopup .mm-react-datepicker__day-name {
  font-size: 1.15rem;
  color: #6a7c9a;
  width: 30px;
}
.mm-calendarPopup .mm-react-datepicker__header {
  background-color: transparent;
  border-bottom: 1px solid transparent;
}
.mm-calendarPopup
  .mm-react-datepicker__header__dropdown.mm-react-datepicker__header__dropdown--scroll {
  display: none;
}
.mm-calendarPopup .mm-react-datepicker__current-month {
  padding: 0 0 15px;
  color: #186fd9;
  font-size: 1.15rem;
}
.mm-calendarPopup .mm-react-datepicker__navigation {
  padding: 0;
  top: 13px;
}
.mm-calendarPopup .mm-react-datepicker__navigation-icon::before {
  border-color: #1a1a1a;
}
.mm-calendarPopup .mm-react-datepicker__day {
  font-size: 1.15rem;
  color: #1a1a1a;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mm-calendarPopup .mm-react-datepicker__month {
  margin: 0rem;
}
.mm-calendarPopup .mm-react-datepicker__day--today,
.mm-calendarPopup .mm-react-datepicker__day--selected {
  background-color: var(--e-global-color-blue-button-color) !important;
  font-weight: normal;
  color: #fff !important;
  border-radius: 100% !important;
}
.mm-calendarPopup .mm-react-datepicker__day-names {
  display: flex;
  justify-content: space-between;
  padding: 0 0 15px;
}
.mm-calendarPopup .mm-react-datepicker__week {
  display: flex;
  justify-content: space-between;
}

.datePickerInput .mm-react-datepicker__tab-loop {
  display: none;
}
.mm-calendarPopup .mm-formGroup {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid var(--e-global-before-light-border-color);
  padding: 20px 0 0;
  margin: 20px 0 0;
}
.mm-mm-btnBlueOutline {
  border: solid 0.2rem var(--e-global-color-blue-button-color);
  background: var(--e-global-color-white);
  font-weight: 600;
  color: var(--e-global-color-blue-button-color);
  padding: 0.7rem 2.5rem;
  border-radius: 0.5rem;
  text-transform: capitalize;
}
.mm-mm-btnBlueOutline:hover {
  background: var(--e-global-color-blue-button-color);
  color: var(--e-global-color-white);
}
.mm-btnBlue {
  border: solid 0.2rem var(--e-global-color-blue-button-color);
  padding: 0.7rem 2.5rem;
  border-radius: 0.5rem;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--e-global-color-white);
  background: var(--e-global-color-blue-button-color);
}
.mm-btnBlue:hover {
  background: var(--e-global-color-white);
  color: var(--e-global-color-blue-button-color);
}

.mm-calendarPopup .mm-formGroup a {
  min-width: 100px;
  text-align: center;
  margin: 0 5px;
  padding: 0.4rem 2.5rem;
  display: flex;
  align-items: center;
}
.mm-ellipse_dropdown {
  position: relative;
}
.mm-ellipse_dropdown_button {
  cursor: pointer;
}
.mm-ellipse_dropdown ul {
  position: absolute;
  border: 1px solid #ebebeb;
  background: #ffffff;
  box-shadow: 1px 3px 4px #00000029;
  border-radius: 4px;
  padding: 10px 20px;
  right: 0;
  z-index: 5;
  min-width: 15rem;
  padding: 1rem;
}
.mm-ellipse_dropdown ul li {
  font-size: 1rem !important;
  margin-bottom: 0.85rem;
  text-align: left;
  padding: 0.5rem 0;
}

.box {
  width: 80%;
  border: 2px dashed;
  height: 180px;
  overflow: auto;
  scroll-behavior: smooth; /* <-- for smooth scroll */
}

.mm-boxChild {
  margin: 600px 0 300px;
  width: 40px;
  height: 40px;
  background: green;
}

.mm-shift_mm-top_middle {
  justify-content: space-between;
}

.mm-modal_scroll {
  height: 100%;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}
.mm-publish_Plan_view_modal {
  height: auto;
  max-height: initial;
}
.mm-publish_Plan_view_modal .mm-shift_tab_list {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.mm-publish_Plan_view_modal .mm-shift_tab_list ul {
  /* width: calc(100% - 185px); */
  flex: 1;
  border-bottom: solid 0.1rem var(--e-global-before-light-border-color);
}
.mm-publish_Plan_view_modal
  .mm-shift_tab_list
  .mm-add_new_user_box_mm-top_right {
  width: 180px;
}
.mm-publish_Plan_view_modal
  .mm-shift_tab_list
  .mm-add_new_user_box_mm-top_right
  a {
  padding: 0.7rem 2.5rem;
  display: block;
  border-radius: 0.5rem;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--e-global-color-white) !important;
  background: var(--e-global-color-blue-button-color) !important;
  text-align: center;
}
.mm-custom_select button {
  width: 100%;
  border: solid 0.1rem var(--e-global-before-light-border-color);
  box-shadow: none;
  height: 4rem;
  border-radius: 4px;
  padding: 0 1rem;
}
.mm-custom_select .css-1v99tuv {
  font-size: 1.4rem;
  font-weight: 600;
  color: #000;
}
.mm-custom_select .css-1wy0on6 > div {
  height: 31px !important;
}
.mm-custom_select button + div {
  width: 100%;
}
.mm-custom_select button + div .css-11unzgr > div {
  display: flex;
  justify-content: flex-start;
}
.mm-custom_select button + div .css-11unzgr > div input {
  width: 20px;
  height: auto;
}

.mm-operatorPopup {
  max-width: 430px;
  left: auto;
}
.mm-operatorPopup h6 {
  color: #6a7c9a;
  font-size: 1.15rem;
  position: relative;
  margin: 0 0 15px;
}
.mm-operatorPopup h6::before {
  content: "";
  right: 0;
  height: 1px;
  width: 170px;
  background-color: #e1e4ea;
  display: block;
  position: absolute;
  top: 11px;
}
.mm-operatorPopup select,
.mm-operatorPopup input[type="text"] {
  width: 100%;
  padding: 0 1rem;
  font-weight: bold;
  height: 4rem;
  border-radius: 25px;
  border: solid 0.1rem var(--e-global-before-light-border-color);
}

body.overlay {
  position: relative;
}
body.overlay::before {
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.4);
}
.overlay .mm-calendarPopup .mm-react-datepicker__day--today {
  background-color: #fff !important;
  color: #000 !important;
}
.overlay .mm-calendarPopup .mm-react-datepicker__day--selected {
  background-color: var(--e-global-color-blue-button-color) !important;
  color: #fff !important;
}

.mm-smallPopupoverlay {
  position: relative;
}
.mm-smallPopupoverlay::before {
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: 999999;
  background: rgba(0, 0, 0, 0);
}

.mm-table_manage_user .mm-all_popup {
  width: 100%;
  background-color: transparent;
}
.mm-table_manage_user .mm-all_popup .mm-add_new_user_box {
  position: absolute;
  top: 0;
  margin-top: 0px;
}

.mm-generalPopup {
  height: initial;
}
.mm-generalPopup .mm-calendarPopup {
  margin-top: 16rem;
}
.mm-mm-addDailyPlanTablemain
  .mm-all_popup
  #excavators_plan.mm-add_new_user_box {
  min-height: 95rem;
  max-width: 587px;
}
.mm-mm-addDailyPlanTablemain .mm-all_popup #trucks_plan.mm-add_new_user_box {
  min-height: 95rem;
  max-width: 587px;
}
.shift_details.active {
  border: 1px solid #186fd9;
}

.date-cal {
  background: url(../images/Calendar.svg);
  background-position: 98% center;
  background-repeat: no-repeat;
  background-size: 3rem;
}

.mm-table_shift_plan_listing .eventTd,
.mm-table_shift_plan_listing .eventTh {
  text-align: center;
}
.mm-table_shift_plan_listing table tbody td {
  white-space: initial !important;
  word-break: initial !important;
}
.mm-table_shift_plan_listing .eventTd .mm-customTooltip p {
  color: #007bff;
  font-weight: 600;
}
.mm-shift_mm-top_middle .mm-daily_button {
  margin-left: 0rem;
}
.mm-new_daily_plan table thead {
  position: initial;
  top: 0;
}

.mm-editIcon {
  display: inline-grid;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-right: 15px;
  position: relative;
  top: 7px;
  cursor: pointer;
}

.mm-popup_overwrite {
  position: absolute;
  left: initial;
  right: 586px;
  z-index: 999999;
}

.mm-acrdnHeadRow {
  display: flex;
  justify-content: space-between;
}
.mm-acrdnHeadRow .left {
  flex: 1;
  display: flex;
  align-items: center;
}
.mm-acrdnHeadRow .left .border {
  flex: 1;
  height: 1px;
  display: block;
  background-color: var(--e-global-before-light-border-color);
  margin: 0 15px;
}
.mm-acrdnHeadRow .left p {
  font-size: 1.3rem;
  font-weight: bold;
  color: var(--e-global-color-dark-blue);
}
.mm-acrdnHeadRow .left p span {
  color: #26a071;
}
.mm-acrdnHeadRow .right {
  width: 20px;
  height: 20px;
}
.mm-acrdnHeadRow .right .expandBtn {
  background-color: #ebebeb;
  width: 20px;
  height: 20px;
  padding: 0;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mm-acrdnHeadRow .right .expandBtndown img {
  transform: rotate(-180deg);
}
.mm-overlay-active-header-inner-dropdown2 {
  z-index: 999999999;
}
.mm-overlay-active-header-inner-dropdown-div2 {
  z-index: 999999999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: red;
}
.overlay-active-table-inner-dropdown {
  z-index: 9999999;
}
.mm-overlay-active-table-inner-dropdown-div {
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: none;
}
.mm-overlay-active-table-inner-dropdown-div.show {
  display: block;
}

.ShiftBox {
  padding: 20px 15px;
}

.mm-operatorSearch {
  position: relative;
}
.mm-operatorSearch input {
  padding: 0 1.9rem 0 1rem !important;
}
.mm-operatorSearch button {
  background: transparent;
  border: none;
  position: absolute;
  right: 20px;
  top: 9px;
}

.shift_details table .forMobile {
  display: none;
}

.mm-loadingMachineSelect #react-select-3-placeholder,
.mm-loadingMachineSelect .css-qc6sy-singleValue {
  font-weight: 600 !important;
  color: #000 !important;
}
.mm-loadingMachineSelect .css-319lph-ValueContainer {
  padding: 0 1.3rem;
}
.border {
  border: solid #000;
  border-width: 0 1px;
}

.mm-hourly-excavators {
  border-top: 0.5px solid grey;
  border-radius: 10px !important;
  /* box-shadow: 1px grey; */
}
.danger-border {
  border-right: 2px solid red;
}

.excavator-buttons {
  border-radius: 5px !important;
  width: 140px;
  font-size: 20px;
  padding-bottom: 5px;
  padding-top: 5px;
  background-color: white;
  border-color: whitesmoke;
  /* box-shadow: 1px 1px 1px 1px #d3d3d3; */
  font-weight: "500";
  color: #000;
}
#button_header::-webkit-scrollbar,
#weather__hourly__forecast::-webkit-scrollbar {
  opacity: 0;
}

.excavator-buttons-selected {
  border-radius: 5px !important;
  width: 140px;
  font-size: 20px;
  padding-bottom: 5px;
  padding-top: 5px;
  background-color: #273f6e;
  border: none;
  /* box-shadow: 1px 1px 1px 1px #d3d3d3; */
  font-weight: "500";
  color: white;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: "absolute";
  top: "50%";
  left: "50%";
  height: 100vh;
}
.shift_details table .forMobile {
  display: none;
}

.mm-loadingMachineSelect #react-select-3-placeholder,
.mm-loadingMachineSelect .css-qc6sy-singleValue {
  font-weight: 600 !important;
  color: #000 !important;
}
.mm-loadingMachineSelect .css-319lph-ValueContainer {
  padding: 0 1.3rem;
}
.mm-latest-accordian {
  height: 700px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 15px;
}
.latest {
  border-bottom: 1px solid var(--e-global-before-light-border-color);
  padding: 15px 0;
}
.latest:last-child {
  border: none;
}
.mm-generate_new_pin_form.mm-my_account_box .form-group {
  margin-bottom: 1.5rem;
}
.mm-generate_new_pin_form.mm-my_account_box .mm-input_field_wrap {
  margin-bottom: 0px;
}
.mm-my_account_box .mm-add_new_user_box_mm-top_right {
  margin-top: 0.5rem;
  justify-content: flex-end;
  display: flex;
}
.mm-my_account_box .mm-add_new_user_box_mm-top_right .save {
  padding: 1rem 2rem;
}
.mm-my_account_box .mm-add_new_user_box_mm-top_right .discard {
  font-weight: bold;
  border-color: #b3c8ed;
  margin-right: 1rem;
}
.mm-table_manage_user tr.active {
  /* border: solid 0.3rem #186FD9; */
  position: relative;
}
.mm-table_manage_user tr.active::after {
  position: absolute;
  display: block;
  content: "";
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  border: solid 0.3rem #186fd9;
}

.mm-table_main
  table
  tbody
  tr:first-child
  .mm-customTooltip
  .mm-customTooltip-show {
  bottom: initial;
  top: 100%;
}
.mm-table_main
  table
  tbody
  tr:first-child
  .mm-customTooltip
  .mm-customTooltip-show::after {
  top: initial;
  bottom: 102%;
  transform: rotate(180deg);
}

.mm-overlayHidden {
  overflow: hidden;
}

.mm-notifiDropdown {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 20px #00000029;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  width: 700px;
  position: absolute;
  right: 0;
}
.mm-notifiDropdownTop {
  padding: 30px 30px 40px 40px;
  display: flex;
}
.mm-notifiDropdownTop h2 {
  font-size: 22px;
  font-weight: bold;
}
.mm-notifiDropdownTop .right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.mm-notifiDropdownTop .right .mm-mm-top_middle_search {
  width: 100%;
  max-width: 180px;
  margin-right: 10px;
}
.mm-notifiDropdownTop .right .mm-mm-top_middle_search input {
  width: 100%;
  height: 35px;
}
.mm-notifiDropdownTop .right .mm-mm-top_middle_search button {
  width: 44px;
  position: relative;
  right: 5px;
  top: -2px;
}
.mm-notifiDropdownTop .right .mm-mm-top_middle_search button i {
  font-size: 1.5rem;
  color: #1a1a1a;
}
.mm-notifiDropdownTop .right .mm-mm-top_middle_filter {
  width: 35px;
  margin-right: 10px;
}
.mm-notifiDropdownTop .right .top_dot_filter {
  width: 35px;
}
.mm-notifiDropdownTop .right .top_dot_filter button {
  padding: 0;
  border-radius: 100%;
}
.mm-notifiDropdownTop .right .top_dot_filter button:focus {
  background-color: #ebebeb;
}
.mm-notifiDropdownTop .right .top_dot_filter button:last-child {
  margin-right: 0px;
}
.mm-notifiDropdownTop .right .top_dot_filter .dropdown-toggle::after {
  display: none;
}
.mm-notifiDropdownTop .right .top_dot_filter .dropdown-menu .dropdown-item {
  width: initial;
  height: initial;
  font-size: initial;
  display: block;
  font-size: 1rem;
  color: #092344;
  text-transform: initial;
  line-height: 20px;
}
.mm-notifiDropdownTop
  .right
  .top_dot_filter
  .dropdown-menu
  .dropdown-item:hover {
  background-color: #ebebeb;
}
.mm-notifiDropdownTop .right .top_dot_filter .dropdown-menu {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 1px 6px 10px #00000029;
  border: 1px solid #ebebeb;
  border-radius: 4px;
}
.mm-notifiDropdownTab ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 40px;
  border: none !important;
}
.mm-notifiDropdownTab ul li {
  width: initial;
  height: initial;
  border-radius: 0px;
  background-color: transparent;
  margin-left: 0;
  margin-right: 30px;
  border: none !important;
  margin-bottom: 0px !important;
}
.mm-notifiDropdownTab ul li a {
  font-size: 1.15rem;
  color: #6a7c9a;
  text-transform: initial;
  border-radius: 0px;
  border: none !important;
  padding: 0 0 8px !important;
}
.mm-notifiDropdownTab ul li a.active {
  color: #186fd9 !important;
  border-bottom: 1px solid #186fd9 !important;
}

.mm-notifiCol {
  border-bottom: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb;
  padding: 25px 40px;
  display: flex;
}
.mm-unreadNotifi {
  background-color: #f2f7ff;
  position: relative;
}
.mm-notifiCol .left {
  flex: 1;
}
.mm-notifiCol .left h3 {
  font-size: 1.15rem;
  color: #1a1a1a;
  font-weight: bold;
  margin-bottom: 15px;
  max-width: 450px;
  position: relative;
}
.mm-unreadNotifi .left h3::before {
  content: "";
  width: 6px;
  height: 6px;
  background-color: #186fd9;
  display: block;
  position: absolute;
  left: -14px;
  top: 6px;
  border-radius: 100%;
}
.mm-notifiCol .left p {
  max-width: 450px;
  color: #1a1a1a;
  font-size: 1.15rem;
  line-height: 20px;
  margin: 0;
}
.mm-notifiCol .left p a {
  color: #186fd9;
  width: initial;
  height: initial;
  font-size: initial;
  display: initial;
  font-weight: 600;
}
.mm-notifiCol .right {
  width: 100%;
  max-width: 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.mm-notifiCol .right .btnGroup {
  visibility: hidden;
  display: flex;
}
.mm-notifiCol:hover .right .btnGroup {
  visibility: visible;
}
.mm-notifiCol.flagactived .right .btnGroup {
  visibility: visible;
}
.mm-notifiCol.flagactived .right .btnGroup .archiveBtn {
  visibility: hidden;
}
.mm-notifiCol.flagactived .right .btnGroup .actionBtn {
  visibility: hidden;
}
.mm-notifiCol.flagactived:hover .right .btnGroup .archiveBtn {
  visibility: visible;
}
.mm-notifiCol.flagactived:hover .right .btnGroup .actionBtn {
  visibility: visible;
}
.mm-notifiCol .right .btnGroup button {
  border: none;
  background-color: transparent;
  padding: 0;
  margin-right: 10px;
  box-shadow: none;
  outline: none;
  width: 30px;
  height: 30px;
  border-radius: 100%;
}
.mm-notifiCol .right .btnGroup button:focus {
  background-color: #ebebeb;
}
.mm-notifiCol .right .btnGroup button:last-child {
  margin-right: 0px;
}
.mm-notifiCol .right .btnGroup .dropdown button {
  margin-right: 0px;
  border-radius: 100%;
}
.mm-notifiCol .right .btnGroup .dropdown-toggle::after {
  display: none;
}
.mm-notifiCol .right .dropdown-menu .dropdown-item {
  width: initial;
  height: initial;
  font-size: initial;
  display: block;
  font-size: 1rem;
  color: #092344;
  text-transform: initial;
  line-height: 20px;
}
.mm-notifiCol .right .dropdown-menu .dropdown-item:hover {
  background-color: #ebebeb;
}
.mm-notifiCol .right .btnGroup .dropdown-menu {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 1px 6px 10px #00000029;
  border: 1px solid #ebebeb;
  border-radius: 4px;
}
.mm-notifiCol .right .btnGroup .flagactive .foractive {
  display: none;
}
.mm-notifiCol .right .btnGroup .flagactive.active .forUnactive {
  display: none;
}
.mm-notifiCol .right .btnGroup .flagactive.active .foractive {
  display: block;
  width: 48px;
}
.mm-notifiCol .right p {
  color: #576b8d;
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
}
.mm-dropdown-setting:hover {
  padding: 0;
  margin: 0;
  background-color: #ebebeb;
}

.dumpPointPopup {
  z-index: 999 !important;
  background-color: white;
  border-radius: 15%;
  font-size: 1rem !important;
  width: 19rem !important;
  min-width: 19rem !important;
  padding: 1.6rem !important;
  border-radius: 0.7rem !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2) !important;
}

.dumpPointPopup2 {
  z-index: 999 !important;
  background-color: white;
  border-radius: 15%;
  font-size: 1rem !important;
  width: 14rem !important;
  min-width: 14rem !important;
  padding: 1.6rem !important;
  border-radius: 0.7rem !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2) !important;
}

.dumpPointPopup > strong {
  display: inline-block;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

.dumpEventPopup {
  z-index: 999 !important;
  background-color: white;
  border-radius: 15%;
  font-size: 1rem !important;
  width: 25rem !important;
  min-width: 15rem !important;
  padding: 1.7rem !important;
  border-radius: 0.7rem !important;
  box-shadow: 0 2px 0.7rem rgba(0, 0, 0, 0.2) !important;
}

.dumpEventPopup > strong {
  display: inline-block;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

.dumpEventPopup > strong {
  display: inline-block;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

.mm-popup-tip-container::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #fff;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
}

.mm-hourly-result-excavator-card tbody td {
  font-weight: 600;
  padding: 5px 0 5px 10px;
}

.mm-hourly-result-excavator-card tbody th {
  color: gray;
  font-weight: normal;
}
.mm-create-source-table {
  border: 1px solid #ebebeb;
  border-collapse: collapse;
}

.mm-global-font-2 {
  font: normal normal normal 12px/17px Open Sans;
  font-size: 1rem;
}

.mm-global-font-1 {
  font: normal normal 600 12px/17px Open Sans;
  font-size: 1rem;
}

.multi-select {
  width: 452px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.hover-row :hover {
  border-bottom: "1px solid #3880F5";
}

.mm-dot-1 {
  height: 0.65rem;
  width: 0.65rem;
  border-radius: 50%;
  display: inline-block;
}
.mm-dot-2 {
  height: 0.65rem;
  width: 0.65rem;
  border-radius: 50%;
  display: inline-block;
}
.popup-width {
  width: 19rem !important;
}

.popup-width-2 {
  width: 9rem !important;
}

.mm-popup-width-3 {
  width: 13rem !important;
}

.mm-source-modal-heading {
  font: normal normal 600 16px/22px Open Sans;
  font-size: 1.4rem;
  margin-left: 5px;
  color: rgb(0, 0, 0);
}

.mm-cursor-pointer {
  cursor: pointer;
}

input[type="checkbox"] {
  accent-color: #057a5f;
}

.mm-custom-popup {
  position: absolute;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.btn-focus:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

div.mm-modal-position div.modal-content {
  box-shadow: var(--e-global-color-white);
  border-radius: 10px 0px 0px 0px;
  opacity: 1;
  margin-top: calc(80px + 20%) !important;
}
div.mm-modal-position {
  max-width: 564px !important;
}
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track-piece {
  background-color: white;
  -webkit-border-radius: 6px;
}
