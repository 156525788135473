
.zone-modal-view::-webkit-scrollbar {
  display: none;
}

.zone-modal-view {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.zone-modal-main-view{
  width: 600px;
}

.zone-modal-btn {
  width: 120px;
  font-size: 12px;
}

.zone-modal-text {
  font-size: 14px;
}

.zone-modal-close-icon {
  cursor: pointer;
  margin: -3% -2% 0 2%;
}

.zone-status {
  font-size: 13px;
}

.item-present {
  display: flex;
  background-color: #fff;
  width: 75%;
  align-items: center;
  justify-content: space-between;
  padding: 12px 10px;
  height: 60px;
}

.item-empty {
  border: 1px dashed #707070;
  width: 75%;
  height: 60px;
}

.drag-item {
  display: flex;
  align-items: center;
  height: 65px;
  margin-bottom: 15px;
}

.drag-card-container{
  width: 100%;
  height: 55px;
}
.drag-card{
  width: 100%;
  height: 55px;
  border-radius: 7px;
  display: flex;
  font-size: 14px;
  background: white;
  div{
    width: 25%;
    padding-left: 20px;
    display: flex;
    align-items: center;
  }
}
.in-progress-drag-card{
  background: #f5f5f5;
}
.drop-zone-header{
  display: flex;
  padding: 0px 10px 0px 10px;
  margin-left: 50px;
  font-size: 12px;
  p{
    width: 25%;
    padding-left: 20px;
  }
}