.sidebar-container {
  width: 3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: width 0.1s;
  color: #1A1A1A;
  padding-top: 10px;
  background-color: #FCFCFD;
}
.hamburger {
  height: 14px;
  width: 28px;
  cursor: pointer;
}
.sidebar-active {
  color: #1A1A1A;
  padding-top: 0px;
  width: 220px;
  justify-content: flex-start;
  .hamburger-container {
    height: 85px;
    width: 100%;
    padding: 20px 20px 20px 12px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}
.flip-icon {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.sidebar-menu {
  font-size: 12px;
  padding-top: 5px;
  width: 100%;
  padding: 0px 14px;
  .sub-menu-icon {
    width: 55px;
    font-size: 8px;
    font-weight: normal;
    text-wrap: nowrap;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 3px;
    .iconContainer{
      padding: 0;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      background-color: white;
    }
  }
  .active{
    .iconContainer{
      background-color: #4F74D9;
    }
  }
}
