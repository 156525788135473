.custom-button {
    background-color: #000;
    width: 75%;
    border-radius: 5px;
    cursor: pointer;
    padding:10px 0px;
    display:flex;
    justify-content: center;
    align-items: center;
}

.btn-text {
    color: #fff;
    text-align: center;
    font-size: 13px;
    margin:0;
    border: none;
}

.remember-text {
    color: #092344;
    font-size: 12px;
}

.forgot-text {
    color: #092344;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
}