.water-tower-row {
  font-size: 12px;
  padding: 0.25rem 1rem 0.25rem 1rem;
}

.water-tower-row > td {
  width: 10%;
  text-align: left;
}

.water-tower-row > td:nth-child(6) {
  width: 50%;
  text-align: right;
}
.water-tower-table-header {
  background-color: #f5f7fa;
  th {
    padding: 10px 10px 30px 10px !important;
  }
}

.water-tower-container > table > thead > tr > th {
  width: 10%;
  text-align: left;
}

.water-tower-container > table > thead > tr > th:nth-child(5) {
  width: 50%;
  text-align: right;
}

.water-tower-date-container {
  font-size: 12px;
  background: #2d3f5a !important;
  color: white;
  padding: 0.25rem 1rem 0.25rem 1rem;
  border-radius: 9px;
  width: fit-content;
}
