.close {
  top: 201px;
  left: 1042px;
  width: 11px;
  height: 11px;
  float: right;
  opacity: 1;
  margin: 1%;
  font-weight: bold;
}

.frameless-button {
  background: none;
  border: none;
  padding: 0;
  margin-left: 4%;
}

.popUp-container {
  display: flex;
  overflow-y: hidden;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 14px;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Optional: for a semi-transparent background */
}