.landing-container {
  background: linear-gradient(180deg, #F2F7FF 0%, #DDE5F8 100%);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.home-container {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  padding: 20px;
  margin-bottom: 50px;
}

.department-container {
  display: grid;
  grid-template-columns: repeat(4, 280px);
  grid-template-rows: repeat(3, 190px);
  gap: 30px;
}

.department-item {
  grid-column: span 1;
  grid-row: span 1;
  background: #FAFBFF;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.department-icon {
  max-height: 80%;
  width: auto;
}

.department-item:nth-child(1) {
  grid-column: span 2;
  grid-row: span 3;
  align-items: end;
  padding: 40px 20px 20px 30px;
  .department-name {
    align-self: flex-start;
    font-size: 25px;
  }
  .department-icon {
    max-height: 100%;
    width: auto;
  }
}

.department-item:hover {
  background: #E5EDFF;
}

.department-item:active {
  background: #D1DBF7;
}

.department-disabled {
  cursor: not-allowed;
}

.department-name {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  text-wrap: nowrap;
}

@media screen and (max-width: 1300px) {
  .department-container {
    grid-template-columns: repeat(4, 200px);
    grid-template-rows: repeat(3, 140px);
    gap: 20px;
  }
  .department-item:nth-child(1) {
    .department-icon {
      max-height: 70%;
    }
    .department-name {
      font-size: 16px;
    }
  }
  .department-name {
    font-size: 14px;
  }
  .department-icon {
    max-height: 70%;
    width: auto;
  }
}

@media screen and (max-width: 1000px) {
  .department-container {
    grid-template-columns: repeat(3, 200px);
    grid-template-rows: repeat(3, 140px);
  }
  .department-icon {
    max-height: 80%;
    width: auto;
  }
}

@media screen and (max-width: 700px) {
  .department-container {
    grid-template-columns: repeat(2, 200px);
    grid-template-rows: repeat(2, 140px);
  }
}

@media screen and (max-width: 500px) {
  .department-container {
    grid-template-columns: repeat(1, 250px);
    grid-template-rows: repeat(1, 170px);
  }
  .department-item:nth-child(1) {
    grid-column: span 1;
    grid-row: span 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    .department-name {
      align-self: center;
    }
    .department-icon {
      max-height: 80%;
    }
  }
}
