.mm-P-Update-Success-1 {
  height: 100%;
  width: 100%;

  background-color: rgba(112, 112, 112, 0.54);
  top: 0;
  left: 0;
  z-index: 10;
}

.mm-P-Update-Success-2 {
  background-color: white;
  width: 80%;
  max-width: 554px;

  height: 85%;
  z-index: 20;
  margin-top: 10%;
  font-size: 14px;
}

.heading-1 {
  width: 160px;
  margin-left: 23px;
}

.button-2 {
  padding-left: 60px;
}
.button-operators {
  align-self: center;
  width: 50%;
}

#fname {
  background-color: rgb(210, 210, 210);
  margin-left: 70px;
  padding: 2px 10px 0px 15px;
}

#lname {
  margin-left: 70px;
  padding: 2px 10px 0px 15px;
}

#shift {
  margin-left: 70px;
  padding: 2px 10px 0px 15px;
}

/* Style inputs, select elements and textareas */
select,
textarea {
  padding: 12px;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
  background-color: white;
}

/* Style the label to display next to the inputs */
label {
  padding: 12px 12px 12px 40px;
  display: inline-block;
}

/* Style the submit button */

/* Style the container */
.container {
  border-radius: 5px;
  background-color: white;
  padding: 20px;
}

/* Floating column for labels: 25% width */
.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

/* Floating column for inputs: 75% width */
.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.label-operator {
  font-size: 13px;
  color: #1a1a1a;
  width: 90px;
}

.shift-label {
  color: #1a1a1a;
  font-size: 13px;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .col-25,
  .col-75,
  input[type="submit"] {
    width: 100%;
    margin-top: 0;
  }
}

.input-width {
  width: 350px;
  height: 35px;
  padding: 12px;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
  color: #1a1a1a;
  background-color: #e1e4ea;
  font-size: 14px;
}

.input-dropdown {
  width: 350px;
  height: 45px;
  border: 1px solid #ebebeb;
}

.input-dropdown-2 {
  border: 1px solid #ebebeb;
  height: 33px;
  width: 80px;
  padding-top: 5px;
  padding-right: 1px;
  padding-bottom: 3px;
}

.pop-btn-1 {
  height: 37px;
  width: 85px;
  border: 1px solid black;
}
