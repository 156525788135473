.granularityToggle {
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    padding: 5px 2px;
    display: flex;
}

.selectedToggle {
    background: #2B2D2F;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 15px;
    cursor: pointer;
}

.unselectedToggle {
    border-radius: 4px;
    font-size: 12px;
    font-weight: normal;
    color: #6E6E6E;
    padding: 4px 15px;
    cursor: pointer;
}

.mainContainer {
    display: flex;
    width: 100%;
}

.graphContainer {
    overflow: hidden;
    padding-bottom: 10px;
    user-select: none;
}

.customTooltip {
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #EBEBEB;
    border-radius: 7px;
    padding: 8px;
    width: 185px;
    display: flex;
    flex-direction: column;
}

.bottomBorder {
    border-bottom: 1px solid #EBEBEB;
}

.xTick {
    color: #000000;
    font-size: 12px;
    font-weight: bold;
    padding: 5px;
}

.totalVal {
    padding: 5px;
}

.toolVal {
    color: #000000;
    font-size: 12px;
    font-weight: 600;
}

.toolSubTxt {
    color: #000000;
    font-size: 11px;
    font-style: italic;
}

.analysis {
    color: #646464;
    font-size: 10px;
    display: flex;
    align-items: center;
    gap: 5px;

    div {
        border-bottom: 1px solid #EBEBEB;
        flex-grow: 1;
    }
}

.statusDots {
    display: flex;
    flex-direction: column;
    padding: 5px;
    color: #000000;
    font-size: 10px;
    font-weight: 600;
}

.leftRightToggleContainer {
    position: absolute;
    bottom: -5px;
    display: flex;
    width: 100%;
    font-size: 24px;
    justify-content: space-between;
    padding: 0px 70px 13px 25px;
}

.leftRightToggle {
    height: 32px;
    width: 32px;
    cursor: pointer;
    z-index: 99999999;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-height: 900px) {

    .granularityToggle {
        padding: 4px 2px;
    }

    .selectedToggle {
        font-size: 10px;
        padding: 4px 12px;
    }

    .unselectedToggle {
        font-size: 10px;
        padding: 4px 12px;
    }

    .graphContainer {
        padding-bottom: 8px;
    }
}