.tab-view {
  width: 100%;
}

.tab-list {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid lightgray;
  align-items: flex-end;
  height: 44px;
  width: 100%;
}

.tab-item {
  font-size: 12px;
  margin-right: 70px;
  color: #707070;
  cursor: pointer;
}

.selected-tab {
  color: #000;
  font-weight: 700;
  border-bottom: 2px solid #000;
}
