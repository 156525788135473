.truck-view {
  background-color: #fafbfd;
}

.truck-info-text {
  font-size: 17px;
  font-weight: bold;
}

.light-grey-text {
  color: #807f7f;
}
