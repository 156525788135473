.sidebar-container {
  width: 3rem;
  display: flex;
  flex-direction: column;
  background: #2b2d2f;
  transition: width 0.1s;
  color: white;
}
.sidebar-active {
  width: 220px;
  justify-content: flex-start;

  .active-menu {
    color: white;
    font-weight: bold;
  }
  .hamburger-container {
    height: 85px;
    padding: 20px 20px 20px 12px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}
.hamburger {
  height: 14px;
  width: 28px;
  cursor: pointer;
}
.flip-icon {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.sidebar-menu {
  color: #c1c5c9;
  font-size: 12px;
  padding-top: 5px;
  div {
    padding: 5px 15px;
    cursor: pointer;
  }
}
