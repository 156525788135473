.hide-show-icon {
  cursor: pointer;
  height: auto;
  width: 25px;
  position: absolute;
  top: 30%;
  right: 1%;
}
#float-label {
  position: relative;
}
