.login-view {
  background-color: #f8f8f8;
  flex: 1;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.login-main-view {
  max-width: 500px;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 0 60px 0;
  border-radius: 5px;
  box-shadow: 0px 6px 20px #00000029;
}

.remember-view {
  display: flex;
  align-items: center;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 20px;
}

.info {
  padding: 0 10%;
  text-align: center;
  font-size: 11px;
  color: #677789;
  margin: 10px 0;
}

.login-header {
  font-size: 16px;
  padding: 15px 0px 10px;
}

.login-logo {
  margin-bottom: 20px;
}

.tnc-view {
  margin-top: 1rem;
  display: flex;
  color: black;
  font-size: 13px;
}

@media only screen and (max-width: 600px) {
  .login-view {
    padding: 0rem 1rem 0rem 1rem;
  }
}
